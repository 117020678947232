<template>
    <div id="bar-card">
        <v-card v-if="all_ingredients && bar">
            <v-card-title>{{ bar.strBar }}</v-card-title>
            <v-card-subtitle class="text-left" v-if="bar.strDescription">{{ bar.strDescription }}</v-card-subtitle>
            <v-expansion-panels inset multiple>
                <v-expansion-panel v-for="ing,idx in bar.ingredients" :key="idx">
                    <v-expansion-panel-header>{{ ing.ingredient.strIngredient }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list dense>
                            <v-list-item v-for="bottle,jdx in ing.bottles" :key="jdx">
                                <v-row>
                                    <v-col class="text-left" cols="11">{{ bottle }}</v-col>
                                    <v-col cols="1" v-if="can_edit">
                                        <v-btn small @click="remove_bottle(idx,jdx)"><v-icon>mdi-delete</v-icon></v-btn>
                                    </v-col>
                                    <v-col cols="1" v-else></v-col>
                                </v-row>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-card-actions v-if="can_edit">
                <v-row>
                    <v-col>
                        <v-btn @click="add_bottle_modal=true" color="green">Add Bottle</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn @click="start_edit_bar" color="blue">Edit</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn @click="delete_confirmation=true" color="red">Delete</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>

            <v-dialog v-model="add_bottle_modal">
                <v-card>
                    <v-row>
                        <v-col class="mx-2" cols="5"><v-text-field v-model="new_bottle_name" label="Bottle Name"></v-text-field></v-col>
                        <v-col class="mx-2" cols="5">
                            <v-autocomplete v-if="ingredients" hide-details class="my-2"
                                v-model="new_ingredient"
                                :items="ingredients"
                                item-text="strIngredient"
                                item-value="idIngredient"
                                return-object
                                :filter="returnHeadersAndFilters"
                            >
                                <template v-slot:prepend-item>
                                    <span>
                                        <v-select dense outlined v-model="t_ingredient_type" :items="ingredient_types" label="Ingredient Type">
                                            <template v-slot:item="{ item }">
                                                <span class="d-flex justify-left" style="width: 100%;">
                                                    {{ item }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <span class="d-flex justify-left" style="width: 100%;">
                                        {{ item.strIngredient }}
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-row>
                            <v-col><v-btn @click="add_bottle" color="primary">Add</v-btn></v-col>
                            <v-spacer></v-spacer>
                            <v-col><v-btn @click="cancel_add_bottle" color="secondary">Cancel</v-btn></v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="delete_confirmation">
                <v-card>
                    <v-card-title>Are you sure you want to delete?</v-card-title>
                    <v-card-actions>
                        <v-row>
                            <v-col><v-btn @click="delete_bar" color="red">Delete</v-btn></v-col>
                            <v-spacer></v-spacer>
                            <v-col><v-btn @click="delete_confirmation=false" color="secondary">Cancel</v-btn></v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="edit_modal">
                <v-card>
                    <v-card-title>Update Bar Details</v-card-title>
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td>Bar Name</td>
                                <td><v-text-field v-model="tbar.strBar"></v-text-field></td>
                            </tr>
                            <tr>
                                <td>Bar Description</td>
                                <td><v-textarea v-model="tbar.strDescription"></v-textarea></td>
                            </tr>
                            <tr>
                                <td>Visibility</td>
                                <td><v-switch v-model="tbar.public" :label="tbar.public ? 'Public' : 'Private'"></v-switch></td>
                            </tr>
                            <tr>
                                <td>Shared with</td>
                                <td>
                                    <v-simple-table>
                                        <tbody>
                                            <tr v-for="str,idx in tbar.shared" :key="idx">
                                                <td>{{ str }}</td>
                                                <td><v-btn @click="tbar.shared = tbar.shared.filter(s => s != str)"><v-icon>mdi-delete</v-icon></v-btn></td>
                                            </tr>
                                            <tr>
                                                <td><v-text-field v-model='newshare' :error-messages="newshare_error"></v-text-field></td>
                                                <td><v-btn small @click="add_share"><v-icon>mdi-plus</v-icon></v-btn></td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-card-actions>
                        <v-card-actions>
                        <v-row>
                            <v-col><v-btn @click="edit_bar" color="primary">Save</v-btn></v-col>
                            <v-spacer></v-spacer>
                            <v-col><v-btn @click="cancel_edit" color="secondary">Cancel</v-btn></v-col>
                        </v-row>
                    </v-card-actions>
                    </v-card-actions>
                </v-card>
            </v-dialog>


        </v-card>
    </div>
</template>

<script>
import backend from '@/plugins/backend'
import store from '@/store/index.js'
export default {
    name: "bar-view",
    components: {
    },
    data() {
        return {
            bar_id: this.$route.params.bar_id,
            bar: null,
            delete_confirmation: false,
            edit_modal: false,
            add_bottle_modal: false,
            tbar: {
                strBar: "",
                strDescription: "",
                shared: [],
                public: true,
            },
            t_ingredient_type: 'All',
            new_bottle_name: "",
            new_ingredient: null,
            newshare: "",
            newshare_error: [],
        }
    },
    mounted() {
        if (this.bar_id != null)
            backend.get("/bar/" + this.bar_id)
                .then(r => {
                    this.bar = r.data
                })
        else {
            this.bar = {}
            this.edit_modal = true
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.user() != null;
        },
        can_edit() {
            return this.isLoggedIn && (this.$store.getters.user().id == this.bar.strOwner 
                    || this.$store.getters.superusers().includes(this.$store.getters.user().id))
        },
        all_ingredients() {
            return this.$store.getters.all_ingredients()
        },
        ingredient_types() {
            return this.$store.getters.ingredient_types()
        },
        ingredient_groups() {
            return this.$store.getters.ingredient_groups()
        },
        ingredients() {
            if (this.t_ingredient_type == 'All')
                return this.all_ingredients
            return this.all_ingredients.filter(e => e.strType && e.strType == this.t_ingredient_type)
        },
    },
    methods: {
        returnHeadersAndFilters(item, queryText, itemText) {
            if (item.header) {
                return [...this.ingredient_groups[item.header]].filter(e => e.strIngredient.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1).length > 0
            }
            return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        },
        cancel_add_bottle() {
            this.new_bottle_name = ""
            this.new_ingredient = null
            this.t_ingredient_type = 'All'
            this.add_bottle_modal = false
        },
        remove_bottle(ing_idx,b_idx) {
            if (this.bar.ingredients[ing_idx].bottles.length == 1) {
                this.bar.ingredients.splice(ing_idx, 1)
            }
            else {
                this.bar.ingredients[ing_idx].bottles.splice(b_idx, 1)
            }
            backend.put('/bar/' + this.bar.idBar, this.bar)
                .then(r => {
                    store.dispatch('set_bar', this.bar)
                    this.bar = r.data
                })
        },
        add_bottle() {
            let idx = this.bar.ingredients.findIndex(e => e.ingredient.idIngredient == this.new_ingredient.idIngredient)
            if (idx == -1) {
                this.bar.ingredients.push({ingredient: {idIngredient: this.new_ingredient.idIngredient, strIngredient: this.new_ingredient.strIngredient, idType: this.new_ingredient.idType, strType: this.new_ingredient.strType}, bottles: [this.new_bottle_name]})
            }
            else {
                if (this.new_bottle_name) {
                    this.bar.ingredients[idx].bottles = [...((new Set(this.bar.ingredients[idx].bottles)).add(this.new_bottle_name))]
                }
            }
            backend.put('/bar/' + this.bar.idBar, this.bar)
                .then(r => {
                    store.dispatch('set_bar', this.bar)
                    this.bar = r.data
                    this.cancel_add_bottle()
                })
        },
        add_share() {
            if (this.newshare.length > 0) {
                this.tbar.shared = [...((new Set(this.tbar.shared)).add(this.newshare))]
                this.newshare=''
                this.newshare_error = []
            }
            else {
                this.newshare_error = ["Cannot be empty to add a share"]
            }
        },
        delete_bar() {
            backend.delete('/bar/' + this.bar.idBar)
                 .then(r =>{
                    store.dispatch('unset_bar')
                    this.$router.go(-1)
                 })
        },
        update_bar() {
            backend.put('/bar/' + this.bar.idBar, this.bar)
                .then(r => {
                    store.dispatch('set_bar', this.bar)
                    this.bar = r.data
                })
        },
        start_edit_bar() {
            this.tbar.strBar = this.bar.strBar
            this.tbar.strDescription = this.bar.strDescription
            this.tbar.shared = this.bar.shared
            this.edit_modal = true
        },
        edit_bar() {
            if (this.bar_id != null) {
                this.bar.strBar = this.tbar.strBar
                this.bar.strDescription = this.tbar.strDescription
                this.bar.shared = this.tbar.shared
                backend.put('/bar/' + this.bar.idBar, this.bar)
                    .then(r => {
                        store.dispatch('set_bar', this.bar)
                        this.bar = r.data
                        this.tbar = {strBar: "", strDescription: "", shared: [], public: true}
                        this.edit_modal = false
                    })
            }
            else {
                this.tbar.strOwner = this.$store.getters.user().id
                this.tbar.ingredients = []
                backend.put('/bar', this.tbar)
                    .then(r => {
                        store.dispatch('set_bar', this.bar)
                        this.bar = r.data
                        this.tbar = {strBar: "", strDescription: "", shared: [], public: true}
                        this.edit_modal = false
                    })
            }
        },
        cancel_edit() {
            if (this.bar_id != null) {
                this.edit_modal = false
            }
            else {
                this.$router.go(-1)
            }
        }
    }
}

</script>