<template>
    <div class="recipe-search">
        <v-container v-if="all_ingredients && recipe_list">
            <v-row class="mx-2" align="center">
                <v-col cols="10">
                    <v-text-field v-model="search_string" label="Search:" @keydown.enter="new_search_string"></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-btn @click="do_search">Search</v-btn>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                @click="toggle_facet"
                                fab :outlined="show_facet" small
                                color="primary"
                                v-bind="attrs" v-on="on"
                            >
                                <v-icon>
                                    mdi-filter-menu-outline
                                </v-icon> 
                            </v-btn>
                        </template>
                        <span v-if="show_facet">Hide Filters</span>
                        <span v-else>Show Filters</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="6"> {{ num_results }} results </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="1">
                    <v-select :items="[6, 12, 24, 48]" v-model="num_per_page" @change="do_search"></v-select>
                </v-col>
                <v-col cols="1" class="text-caption">/ page</v-col>
            </v-row>
            <v-row class="mx-1">
                <v-col v-if="show_facet" xs="12" sm="6" md="4" lg="4" xl="4">
                    <v-row align="center" justify="center">
                        <v-autocomplete 
                            v-model="ingredient_incl" 
                            :items="not_exclude"
                            :item-text="(o) => o.strIngredient"
                            :item-value="(o) => o.idIngredient"
                            @change="new_search"
                            multiple chips
                            deletable-chips
                            return-object
                            label="Include">
                            <template v-slot:prepend-item>
                                <span>
                                    <v-select dense outlined v-model="t_ingredient_type" :items="ingredient_types" label="Ingredient Type">
                                        <template v-slot:item="{ item }">
                                            <span class="d-flex justify-left" style="width: 100%;">
                                                {{ item }}
                                            </span>
                                        </template>
                                    </v-select>
                                </span>
                            </template>
                            <template v-slot:item="{ item }">
                                <span class="d-flex justify-left" style="width: 100%;">
                                    {{ item.strIngredient }}
                                </span>
                            </template>
                        </v-autocomplete>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-autocomplete 
                            v-model="ingredient_excl" 
                            :items="not_include"
                            :item-text="(o) => o.strIngredient"
                            :item-value="(o) => o.idIngredient"
                            @change="new_search"
                            multiple chips
                            deletable-chips
                            return-object
                            label="Exclude">
                            <template v-slot:prepend-item>
                                <span>
                                    <v-select dense outlined v-model="t_ingredient_type" :items="ingredient_types" label="Ingredient Type">
                                        <template v-slot:item="{ item }">
                                            <span class="d-flex justify-left" style="width: 100%;">
                                                {{ item }}
                                            </span>
                                        </template>
                                    </v-select>
                                </span>
                            </template>
                            <template v-slot:item="{ item }">
                                <span class="d-flex justify-left" style="width: 100%;">
                                    {{ item.strIngredient }}
                                </span>
                            </template>
                        </v-autocomplete>
                    </v-row>
                    <v-row v-if="isLoggedIn">
                        <v-switch v-model=filter_user label="Restrict to my recipes" @change="new_search"></v-switch>
                    </v-row>
                    <v-row v-if="bar">
                        <v-switch v-if="bar" v-model=search_with_bar label="Restrict to my bar" @change="restrict_to_bar"></v-switch>
                    </v-row>
                    <!-- <v-row>
                        <v-checkbox v-model="rand_sort" @change="do_rand_sort" label="Random Sort"></v-checkbox>
                    </v-row> -->
                    <v-row align="center" justify="center">
                        <v-btn @click="clear_filters">
                            Clear
                        </v-btn>
                    </v-row>
                </v-col>
                <v-col>
                    <v-container>
                        <v-row>
                            <v-btn
                                fab x-small
                                @click="previous_page()"
                                :disabled="current_page == 1"
                            >
                                <v-icon>mdi-less-than</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            Page {{ current_page }} of {{ last_page }}
                            <v-spacer></v-spacer>
                            <v-btn
                                fab x-small
                                @click="next_page()"
                                :disabled="current_page == last_page"
                            >
                            <v-icon>mdi-greater-than</v-icon>
                            </v-btn>
                        </v-row>
                        <v-row>
                            <v-col :xl="show_facet ? 6 : 4" xs="12" :sm="show_facet ? 12 : 6" :md="show_facet ? 6 : 4" :lg="show_facet ? 6 : 4" v-for="recipe in recipe_list" :key="recipe.idDrink">
                                <v-card class="d-flex flex-column fill-height">
                                        <recipe-card-brief :recipe="recipe"
                                            @recipe_card_brief_incl="include_ingredient" 
                                            @recipe_card_brief_excl="exclude_ingredient" 
                                        ></recipe-card-brief>
                                    <v-spacer></v-spacer>
                                    <v-card-actions>
                                        <v-btn
                                            color="primary" text
                                            @click="goto_recipe(recipe.idDrink)"
                                        >Goto Recipe</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-btn
                                fab x-small
                                @click="previous_page()"
                                :disabled="current_page == 1"
                            >
                                <v-icon>mdi-less-than</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            Page {{ current_page }} of {{ last_page }}
                            <v-spacer></v-spacer>
                            <v-btn
                                fab x-small
                                @click="next_page()"
                                :disabled="current_page == last_page"
                            >
                            <v-icon>mdi-greater-than</v-icon>
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
        <v-skeleton-loader v-else type="table-heading, table-row@4, divider"></v-skeleton-loader>
    </div>
</template>

<script>
import RecipeCardBrief from '@/components/RecipeCardBrief.vue'
import backend from '@/plugins/backend'

export default {
    name: 'RecipeSearchView',
    components: {
        RecipeCardBrief,
    },
    data() {
        return {
            recipe_list: null,

            search_string: "",
            ingredient_incl: [],
            ingredient_excl: [],
            origin: [],
            sort: null,
            filter_user: false,
            num_per_page: null,
            current_page: null,

            num_results: 0,
            show_facet: true,
            search_with_bar: false,
            rand_sort: false,

            t_ingredient_type: 'All',
        }
    },
    mounted() {
        var stored_search = this.$store.getters.search()
        if (stored_search == null)
            stored_search = this.default_search()
        this.search_string = stored_search.search_string
        this.ingredient_incl = stored_search.ingredient_incl
        this.ingredient_excl = stored_search.ingredient_excl
        this.current_page = stored_search.current_page
        this.num_per_page = stored_search.num_per_page
        this.sort = stored_search.sort
        this.filter_user = stored_search.filter_user == "Yes"
        this.search_with_bar = stored_search.bar_id ? true : false
        if (this.sort)
            this.rand_sort = true

        this.do_search()
    },
    methods: {
        default_search() {
            return {
                search_string: "",
                ingredient_incl: [],
                ingredient_excl: [],
                current_page: 1,
                num_per_page: 12,
            }
        },
        do_search() {
            console.log("SEARCH", this.search)
            backend.post("/search?page_size=" + this.num_per_page + "&page=" + this.current_page, this.search)
                 .then(r => {
                    this.recipe_list = r.data.data
                    this.num_results = r.data.summary.num_results
                 })
            this.$store.dispatch('save_search', this.saved_search)
        },
        previous_page() {
            this.current_page = this.current_page - 1
            this.do_search()
        },
        next_page() {
            this.current_page = this.current_page + 1
            this.do_search()
        },
        toggle_facet() {
            this.show_facet = !this.show_facet
        },
        include_ingredient(ing_id) {
            const ingredient = this.ingredients.find((i) => i.idIngredient == ing_id)
            this.ingredient_incl.push(ingredient)
            this.new_search()
        },
        exclude_ingredient(ing_id) {
            const ingredient = this.ingredients.find((i) => i.idIngredient == ing_id)
            this.ingredient_excl.push(ingredient)
            this.new_search()
        },
        clear_filters() {
            this.ingredient_incl = []
            this.ingredient_excl = []
            this.search_string = ""
            this.filter_user = false
            this.do_search()
        },
        do_rand_sort() {
            if (this.rand_sort)
                this.sort = {key: 'rand', direction: Math.floor(Math.random() * 1000)}
            else
                this.sort = null
            this.new_search()
        },
        new_search_string() {
            this.rand_sort = false
            this.sort = null
            this.new_search()
        },
        new_search() {
            this.current_page = 1
            this.do_search()
        },
        goto_recipe(id) {
            this.$router.push({name: 'recipe_view', params: {'recipe_id': id}})
        },
        restrict_to_bar() {
            // if (this.search_with_bar) {
            //     this.ingredient_incl = this.ingredient_incl.filter(e => this.bar.ingredients.map(f => f.idIngredient).includes(e.idIngredient))
            //     this.ingredient_excl = this.ingredient_excl.filter(e => this.bar.ingredients.map(f => f.idIngredient).includes(e.idIngredient))
            // }
            this.do_search()
        },
        returnHeadersAndFilters(item, queryText, itemText) {
            if (item.header) {
                return [...this.ingredients[item.header]].filter(e => e.strIngredient.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1).length > 0
            }
            return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        },
        addHeadersToList(list) {
            var tingobj = list.reduce(function(rv, x) { (rv[x['ingredient']['strType']] = rv[x['ingredient']['strType']] || []).push(x['ingredient']); return rv;}, {})
            var tinglist = []
            Object.entries(tingobj).forEach(function(e) {tinglist = [...tinglist, {'header': e[0]}, ...e[1]];})
            return tinglist 
        },
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.user() != null;
        },
        all_ingredients() {
            return this.$store.getters.all_ingredients()
        },
        ingredient_types() {
            return this.$store.getters.ingredient_types()
        },
        ingredient_groups() {
            return this.$store.getters.ingredient_groups()
        },
        ingredients_1() {
            if (this.search_with_bar)
                return this.bar_ingredients
            return this.all_ingredients
        },
        ingredients() {
            if (this.t_ingredient_type == 'All')
                return this.ingredients_1
            return this.ingredients_1.filter(e => e.strType && e.strType == this.t_ingredient_type)
        },
        not_include() {
            return this.ingredients.filter(x => !this.ingredient_incl.includes(x))
        },
        not_exclude() {
            return this.ingredients.filter(x => !this.ingredient_excl.includes(x))
        },
        last_page() {
            return Math.floor((this.num_results + this.num_per_page - 1) / this.num_per_page)
        },
        saved_search() {
            return {
                ingredient_incl: this.ingredient_incl,
                ingredient_excl: this.ingredient_excl,
                search_string: this.search_string,
                num_per_page: this.num_per_page,
                current_page: this.current_page,
                sort: this.sort,
                filter_user: this.filter_user,
                bar_id: this.search_bar_id,
            }
        },
        search() {
            var retval = {
                ingredient_incl: this.ingredient_incl.map(e => e.idIngredient),
                ingredient_excl: this.ingredient_excl.map(e => e.idIngredient),
                search_string: this.search_string,
                origin: this.origin,
                sort: this.sort,
                filter_user: this.filter_user ? "Yes" : "No",
                bar_id: this.search_bar_id,
            }
            // if ((retval.ingredient_incl.length > 0) 
            //     || (retval.ingredient_excl.length > 0) 
            //     || (retval.search_string != ""))
            //     retval.sort = null;
            return retval
        },
        user() {
            return this.$store.getters.user()
        },
        bar() {
            return this.$store.getters.bar()
        },
        search_bar_id() {
            return this.search_with_bar ? (this.bar ? this.bar.idBar : null) : null
        },
        bar_ingredients() {
            if (this.bar != null)
                return this.addHeadersToList(this.bar.ingredients)
            return null
        }
    },
}
</script>
