<template>
    <div class="ai-recipe-search">
        <v-container>
            <v-row class="mx-2" align="center">
                <v-col cols="9">
                    <v-select 
                        v-model="qualifier" 
                        :items="qualifier_options" 
                        item-text="text" 
                        item-value="value"
                        return-object single-line
                    >
                    </v-select>
                </v-col>
                <v-col cols="3"></v-col>
            </v-row>
            <v-row class="mx-2" align="center">
                <v-col cols="9">
                    <v-text-field v-model="search_string" label="Search:" @keydown.enter="do_search"></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-btn @click="do_search">Search</v-btn>
                </v-col>
                <v-col cols="1">
                    <v-checkbox v-model="advanced"></v-checkbox>
                </v-col>
            </v-row>
            <v-row v-if="advanced">
                <v-col cols="6">
                    <v-slider 
                        v-model="num_recipes" min="1" max="10" 
                        @change="do_search" 
                        label="Num Recipes"
                        :thumb-size="20"
                        thumb-label="always"
                    >
                    </v-slider>
                </v-col>
                <v-col cols="6">
                    <v-slider 
                        v-model="temperature" min="0.0" max="1.0" step="0.1" 
                        @change="do_search" 
                        label="Temperature"
                        :thumb-size="20"
                        thumb-label="always"
                    ></v-slider>
                </v-col>
            </v-row>
            <v-row v-if="!recipes" justify="center"><v-progress-circular indeterminate color="primary"></v-progress-circular></v-row>
            <v-row v-for="recipe,idx in recipes" :key="idx">
                <v-card class="d-flex flex-column fill-height ma-3 pa-3">
                    <v-card-title style="word-break: break-word"><div class="text-h3"><b><font color="blue"> {{ recipe.name }} </font></b></div></v-card-title>

                    <v-list dense>
                        <v-list-item-title class="text-left"><b>Ingredients:</b></v-list-item-title>
                        <v-list-item dense>
                            <v-simple-table dense>
                                <tbody>
                                    <tr v-for="ing,idx2 in recipe.ingredients" :key="idx2">
                                        <td class="text-left">
                                            <v-icon>mdi-circle-small</v-icon> &nbsp; {{ ing.ingredient }} &nbsp; <font color="blue"> {{ ing.amount }}</font>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-list-item>

                        <div v-if="recipe.glass">
                            <v-divider></v-divider>
                            <v-list-item-title class="text-left my-2"><b>Glass:</b> &nbsp; {{ recipe.glass }} </v-list-item-title>
                        </div>

                        <div v-if="recipe.garnish">
                            <v-divider></v-divider>
                            <v-list-item-title class="text-left my-2"><b>Garnish:</b> &nbsp; {{ recipe.garnish }} </v-list-item-title>
                        </div>

                        <v-divider></v-divider>
                        <v-list-item-title class="text-left"><b>Instructions:</b></v-list-item-title>
                        <v-list-item dense>
                            <v-simple-table dense>
                                <tbody>
                                    <tr>
                                        <td class="text-left">
                                            {{ recipe.instructions }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-list-item>

                        <div v-if="recipe.suggestions">
                            <v-divider></v-divider>
                            <v-list-item-title class="text-left text-caption my-2" v-if="recipe.suggestions"><b>Suggestions:</b></v-list-item-title>
                            <v-list-item dense v-if="recipe.suggestions" class="text-left text-caption font-italic">{{ recipe.suggestions }}</v-list-item>
                        </div>
                    </v-list>

                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import backend from '@/plugins/backend'
const url = "/ai" 

export default {
    name: 'AiRecipeSearch',
    data() {
        return {
            recipes: [],
            search_string: "",
            temperature: 0.5,
            num_recipes: 1,
            advanced: false,
            raw_data: null,
            qualifier_options: [
                {"text": "Show me a recipe for a cocktail named ", "value": "named"}, 
                {"text": "Show me a recipe for a cocktail that ", "value": "that"}
            ],
            qualifier: {"text": "Show me a recipe for a cocktail named ", "value": "named"},
        }
    },
    computed: {
        search_data() {
            return {
                "model": "text-davinci-003",
                "prompt": 'Show me a recipe in ounces for a cocktail' + this.qualifier.value + ' ' + this.search_string + '. Your answer should be in valid JSON format for Python. For example: {"name": "My Cocktail", "description": "This is a nice cocktail for a cold day", "ingredients": [ {"ingredient": "Bourbon whiskey", "amount": "2 ounces"}, {"ingredient": "Sweet vermouth", "amount": "1 ounce"}, {"ingredient": "Angostura bitters", "amount": "2 dashes"}], "instructions": "Combine all ingredients in a mixing glass with ice. Stir until chilled. Strain into glass", "glass": "Coupe glass", "garnish": "Maraschino cherry", "suggestions": "You can also use Rye instead of Bourbon." }',
                "max_tokens": 256,
                "temperature": this.temperature / 100.0,
                "n": this.num_recipes,
            }
        }
    },
    methods: {
        do_search() {
            this.recipes = null
            console.log("SEARCH: ", this.search_data)
            // const l_header = {headers: {Authorization: `Bearer ${key}`}}
            const l_header = null
            backend.post(url, this.search_data, l_header)
                 .then( r => {
                    this.raw_data = r.data
                    console.log("RESP: ", r.data)
                    this.recipes = r.data.choices.map(o => JSON.parse(o.text))
                 })
        }
    }
}

</script>