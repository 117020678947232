<template>
    <div class="importit">
        <v-card class="ma-2 pa-2" elevation="0">
            <v-row justify="center">
                <v-card width="75%" class="ma-6 pa-6">
                    <v-text-field v-model="url" label="URL"></v-text-field>
                    <v-card-actions>
                        <v-row>
                            <v-col><v-btn @click="$router.push({name: 'new_recipe', params: {import_url: url}})" color="green">Go</v-btn></v-col>
                            <v-spacer></v-spacer>
                            <v-col><v-btn @click="$router.back()" color="secondary">Cancel</v-btn></v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "edit-recipe",
    data() {
        return {
            url: "",
        }
    }
}
</script>
