<template>
    <div id="recipe-card-brief">
        <v-card flat class="ma-3 pa-3">
            <v-card-title style="word-break: break-word"> {{ recipe.strDrink }} </v-card-title>
            <!-- Display some recipe info -->
            <v-card-text>
            </v-card-text>
            <div class="text-left">
                <v-menu v-for="ing,idx in recipe.ingredients" :key="idx">
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip color="blue" 
                            :outlined="!bar_has_ingredient(ing)" 
                            :text-color="bar_has_ingredient(ing) ? 'white' : 'blue'" 
                            small class="mx-1" 
                            v-bind="attrs"
                            v-on="on"
                        >{{ ing.ingredient.strIngredient }}</v-chip>

                        <!-- <v-chip color="blue" outlined x-small class="mx-1"
                            v-bind="attrs"
                            v-on="on"
                        >{{ ing.ingredient.strIngredient }}</v-chip> -->
                    </template>
                    <v-list>
                        <v-list-item @click="$emit('recipe_card_brief_incl',ing.ingredient.idIngredient)">Include {{ ing.ingredient.strIngredient }}</v-list-item>
                        <v-list-item @click="$emit('recipe_card_brief_excl',ing.ingredient.idIngredient)">Exclude {{ ing.ingredient.strIngredient }}</v-list-item>
                        <v-list-item v-if="bar && !bar_has_ingredient(ing)"
                            @click="add_to_bar(ing)"
                        >Add to Bar</v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-card>
    </div>
</template>

<script>
import backend from '@/plugins/backend'
import store from '@/store/index.js'

export default {
    name: "recipe-card-brief",
    props: {
        recipe: Object,
    },
    computed: {
        ingredients() {
            if (this.recipe.ingredients == null)
                return {}
            const inames = this.recipe.ingredients.map(x => x.ingredient.strIngredient)
            return inames.filter((c,i) => { return inames.indexOf(c) == i })
        },
        bar() {
            return this.$store.getters.bar()
        },
    },
    methods: {
        bar_has_ingredient(ing) {
            if (this.recipe == null)
                return false
            if (this.bar == null)
                return false
            var bing = this.bar.ingredients.find(e => e.ingredient.idIngredient == ing.ingredient.idIngredient)
            if (bing)
                return true
            else
                return false
        },
        add_to_bar(ing) {
            if (this.bar != null) {
                if (!this.bar_has_ingredient(ing)) {
                    var tbar = {...this.bar}
                    tbar.ingredients.push({ingredient: ing.ingredient , bottles: [""]})
                    backend.put('/bar/' + this.bar.idBar, tbar)
                        .then(r => {
                            store.dispatch('set_bar_by_id', this.bar.idBar)
                        })
                }
            }
        },
    }
}
</script>
