<template>
    <div id="bar-list">
        <v-card>
            <v-card-title>Bars</v-card-title>
            <v-expansion-panels v-model="expansions" multiple v-if="bars">
                <v-expansion-panel v-if="private_bars.length > 0">
                    <v-expansion-panel-header>Private Bars</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list>
                            <v-list-item v-for="b,idx in private_bars" :key="idx" @click="goto_bar(b)">
                                <v-list-item-title>{{ b.strBar }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="public_bars.length > 0">
                    <v-expansion-panel-header>Public Bars</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list>
                            <v-list-item v-for="b,idx in public_bars" :key="idx" @click="goto_bar(b)">
                                <v-list-item-title>{{ b.strBar }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-card-actions>
                <v-btn v-if="isLoggedIn" @click="new_bar" color="primary">New Bar</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import backend from '@/plugins/backend'
export default {
    name: "bar-list",
    components: {
    },
    data() {
        return {
            bars: null,
            expansions: [ 0 ],
        }
    },
    mounted() {
        backend.post("/bars")
             .then(r => {
                this.bars = r.data
             })
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.user() != null;
        },
        public_bars() {
            if (this.bars) {
                return this.bars.filter(b => b.public)
            }
            else
                return []
        },
        private_bars() {
            if (this.bars) {
                return this.bars.filter(b => !b.public)
            }
            else
                return []
        },
    },
    methods: {
        goto_bar(b) {
            this.$router.push({name: 'bar_view', params: {bar_id: b.idBar}})
        },
        new_bar() {
            this.$router.push({name: 'new_bar'})
        }
    }
}
</script>
