<template>
    <div class="recipe-details">
        <v-card>
            <recipe-card :recipe="recipe"></recipe-card>
        </v-card>
    </div>
</template>

<script>
import RecipeCard from '@/components/RecipeCard.vue'
import backend from '@/plugins/backend'

export default {
    name: 'RecipeDetailsView',
    components: {
        RecipeCard,
    },
    data() {
        return {
            recipe_id: this.$route.params.recipe_id,
            recipe: null,
        }
    },
    created() {
        // this.recipe = recipe_data.recipe_by_id(this.recipe_id)
        backend.get("/recipe/" + this.recipe_id)
             .then(r => {
                this.recipe = r.data
             })
    },
    methods: {
    }
}
</script>
