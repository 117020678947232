<template>
    <div id="recipe-card">
        <v-card class="ma-3 pa-3" outlined width="550" v-if="recipe">
            <v-card-title style="word-break: break-word"><div class="text-h3"><b><font color="blue"> {{ recipe.strDrink }} </font></b></div></v-card-title>
            <v-card-subtitle class="text-left" v-if="recipe.strCreatedBy">Contributed By {{ display_name(recipe.strCreatedBy) }}</v-card-subtitle>
            <v-card-subtitle class="text-left" v-if="recipe.strOrigin">Origin: {{ recipe.strOrigin }}</v-card-subtitle>
            <v-card-text class="text-left text-subtitle-1" v-if="recipe.strDescription">{{ recipe.strDescription }}</v-card-text>
            <v-list dense>
                <v-list-item-title class="text-left"><b>Ingredients:</b></v-list-item-title>
                <v-list-item dense>
                    <v-list-item-content>
                        <v-list dense>
                            <v-list-item v-for="ing,idx in recipe.ingredients" :key="idx" dense>
                                <v-list-item-content>
                                    <v-row align="center" v-if="bar_bottles(ing).length == 0" no-gutters>
                                        &nbsp; <v-icon>mdi-square-medium-outline</v-icon> 
                                        &nbsp; <font color="blue"> {{ ing.strMeasure }}</font> 
                                        &nbsp; {{ ing.ingredient.strIngredient }}
                                    </v-row>
                                    <v-row align="center" v-else-if="bar_bottles(ing).length == 1" no-gutters>
                                        &nbsp; <v-icon>mdi-circle-medium</v-icon> 
                                        &nbsp; <font color="blue"> {{ ing.strMeasure }}</font> 
                                        &nbsp; {{ ing.ingredient.strIngredient }}
                                    </v-row>
                                    <v-row align="center" v-else no-gutters>
                                        &nbsp; <v-icon>mdi-circle-medium</v-icon> 
                                        &nbsp; <font color="blue"> {{ ing.strMeasure }}</font> 
                                        <v-autocomplete
                                            v-model="ingredient_choice[idx]"
                                            :items="bar_bottles(ing)"
                                            dense solo flat hide-details
                                            append-icon="mdi-bottle-wine"
                                        >
                                        <template v-slot:item="{ item }">
                                            <span class="d-flex justify-left" style="width: 100%;">
                                                {{ item }}
                                            </span>
                                        </template>
                                        </v-autocomplete>
                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item-title class="text-left my-2"><b>Glass:</b> &nbsp; {{ recipe.glass.strGlass }} </v-list-item-title>

                <v-divider></v-divider>
                <v-list-item-title class="text-left"><b>Instructions:</b></v-list-item-title>
                <v-list-item-title class="text-left"><v-textarea auto-grow rows="1" readonly :value="recipe.strInstructions"></v-textarea></v-list-item-title>
            </v-list>
            <v-divider></v-divider>
            <v-list v-if="recipe.variations && recipe.variations.length > 0">
                <v-list-item-title class="text-left"><b>Variations:</b></v-list-item-title>
                <v-list-item dense>
                    <v-simple-table dense>
                        <tbody>
                            <tr v-for="v in recipe.variations" :key="v.idDrink">
                                <td style="vertical-align:middle; text-align:left"><router-link :to="'/recipe/'+v.idDrink">{{ v.strDrink }}</router-link></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <div class="text-left my-2">
                <!-- <v-chip color="blue" :outlined="bar_bottles(ing).length == 0" :text-color="bar_bottles(ing).length == 0 ? 'blue' : 'white'" small class="mx-1" v-for="ing,idx in recipe.ingredients" :key="idx">{{ ing.ingredient.strIngredient }}</v-chip> -->
                <v-menu v-for="ing,idx in recipe.ingredients" :key="idx">
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip color="blue" 
                            :outlined="!bar_has_ingredient(ing)" 
                            :text-color="bar_has_ingredient(ing) ? 'white' : 'blue'" 
                            small class="mx-1" 
                            v-bind="attrs"
                            v-on="on"
                        >{{ ing.ingredient.strIngredient }}</v-chip>

                    </template>
                    <v-list>
                        <v-list-item @click="$emit('recipe_card_brief_incl',ing.ingredient.idIngredient)">Include {{ ing.ingredient.strIngredient }}</v-list-item>
                        <v-list-item @click="$emit('recipe_card_brief_excl',ing.ingredient.idIngredient)">Exclude {{ ing.ingredient.strIngredient }}</v-list-item>
                        <v-list-item v-if="bar && !bar_has_ingredient(ing)"
                            @click="add_to_bar(ing)"
                        >Add to Bar</v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <v-divider></v-divider>
            <recipe-notes v-if="recipe.idDrink" :recipe_id="recipe.idDrink"></recipe-notes>
            <v-divider></v-divider>
            <v-card-actions v-if="isLoggedIn">
                <v-row>
                    <v-col>
                        <v-btn :disabled="!can_edit" @click="edit_recipe" color="blue">Edit</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn @click="new_variation" color="green">New Variation</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn :disabled="!can_edit" color="red" @click="delete_confirmation=true">Delete</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>

            <v-dialog v-model="delete_confirmation">
                <v-card>
                    <v-card-title>Are you sure you want to delete?</v-card-title>
                    <v-card-actions>
                        <v-card-actions>
                        <v-row>
                            <v-col><v-btn @click="delete_recipe" color="red">Delete</v-btn></v-col>
                            <v-spacer></v-spacer>
                            <v-col><v-btn @click="delete_confirmation=false" color="secondary">Cancel</v-btn></v-col>
                        </v-row>
                    </v-card-actions>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import backend from '@/plugins/backend'
import store from '@/store/index.js'
import RecipeNotes from '@/components/RecipeNotes.vue'

export default {
    name: "recipe-card",
    components: {
        RecipeNotes,
    },
    props: {
        recipe: Object,
    },
    data() {
        return {
            delete_confirmation: false,
            ingredient_choice: [], 
        }
    },
    watch: {
        recipe() {
            if (this.recipe != null)
                this.ingredient_choice = this.recipe.ingredients.map(e => this.bar_bottles(e)[0])
        },
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.user() != null;
        },
        ingredients() {
            if (this.recipe.ingredients == null)
                return {}
            const inames = this.recipe.ingredients.map(x => x.ingredient.strIngredient)
            return inames.filter((c,i) => { return inames.indexOf(c) == i })
        },
        bar() {
            return this.$store.getters.bar()
        },
        can_edit() {
            return this.isLoggedIn && (this.$store.getters.user().id == this.recipe.strCreatedBy 
                    || this.$store.getters.superusers().includes(this.$store.getters.user().id))
        },
        // bar_ingredients() {
        //     var bing = this.recipe.ingredients.map(e => this.bar.ingredients.find(ee => ee.idIngredient == e.idIngredient))
        //     return bing.map(e => [e.strIngredient, ...e.bottles.filter(ee => ee.length > 0)])
        // },
    },
    methods: {
        display_name(c) {
            return c.split('@')[0]
        },
        delete_recipe() {
            backend.delete('/recipe/' + this.recipe.idDrink)
                 .then(r => this.$router.go(-1))
        },
        edit_recipe() {
            this.$router.push({'name': 'edit_recipe', params: {'recipe_id': this.recipe.idDrink}})
        },
        new_variation() {
            if (this.recipe.variation_id)
                this.$router.push({'name': 'new_recipe', params: {'variation_id': this.recipe.idVariation}})
            else
                this.$router.push({'name': 'new_recipe', params: {'variation_id': this.recipe.idDrink}})
        },
        bar_bottles(ing) {
            if (this.recipe == null)
                return []
            if (this.bar == null)
                return [ing.ingredient.strIngredient]
            var bing = this.bar.ingredients.find(e => e.ingredient.idIngredient == ing.ingredient.idIngredient)
            if (bing)
                return [ing.ingredient.strIngredient, ...bing.bottles.filter(ee => ee.length > 0)]
            else
                return []
        },
        bar_has_ingredient(ing) {
            if (this.recipe == null)
                return false
            if (this.bar == null)
                return false
            var bing = this.bar.ingredients.find(e => e.ingredient.idIngredient == ing.ingredient.idIngredient)
            if (bing)
                return true
            else
                return false
        },
        add_to_bar(ing) {
            if (this.bar != null) {
                if (!this.bar_has_ingredient(ing)) {
                    var tbar = {...this.bar}
                    tbar.ingredients.push({ingredient: ing.ingredient , bottles: [""]})
                    backend.put('/bar/' + this.bar.idBar, tbar)
                        .then(r => {
                            store.dispatch('set_bar_by_id', this.bar.idBar)
                        })
                }
            }
        },
    }
}
</script>
