<template>
  <v-app>

    <!-- Main content -->
    <v-main>
      <v-app-bar class="">
        <v-row align="center">
          <v-col cols="1">
            <v-btn fab icon @click.stop="drawer = !drawer">
              <v-img src="@/assets/bartender.png" max-height="40" max-width="40"></v-img>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn icon elevation="0" class="myBtn" @click.stop="$router.push({name: 'search'})">
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
          </v-col>
          <v-col cols="2">
          </v-col>
          <v-col cols="2">
          </v-col>
          <v-col cols="3">
          </v-col>
          <v-col cols="1">
            <v-btn icon @click.stop="help = !help">
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
          </v-col>
        </v-row>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary width="350">
        <v-list dense>
          <v-list-item @click.stop="$router.push({name: 'search'})">
            <v-list-item-icon><v-icon>mdi-magnify</v-icon></v-list-item-icon>
            <v-list-item-content>Search Recipes</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon><v-icon>mdi-bottle-wine</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-autocomplete
                v-model="current_bar"
                clearable dense solo hide-details="auto" label="Choose Bar"
                :items="available_bars" item-text="strBar" item-value="idBar"
                return-object
                @change="change_bar"
              >
                <template v-slot:item="{ item }">
                    <span class="d-flex justify-left" style="width: 100%;">
                        {{ item.strBar }}
                    </span>
                </template>
                <template v-slot:selection="{ item }">
                    <span class="d-flex justify-left blue--text" style="width: 100%;">
                        {{ item.strBar }}
                    </span>
                </template>
              </v-autocomplete>              
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="current_bar" @click.stop="$router.push({name: 'bar_view', params: {bar_id: current_bar.idBar}})">
            <v-list-item-icon><v-icon>mdi-bottle-wine-outline</v-icon></v-list-item-icon>
            <v-list-item-content>Go To {{ current_bar.strBar }}</v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="$router.push({name: 'bar_list_view'})">
            <v-list-item-icon><v-icon>mdi-format-list-bulleted-square</v-icon></v-list-item-icon>
            <v-list-item-content>List Bars</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isLoggedIn" @click.stop="$router.push({name: 'new_recipe'})">
            <v-list-item-icon><v-icon>mdi-playlist-plus</v-icon></v-list-item-icon>
            <v-list-item-content>New Recipe</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isLoggedIn" @click.stop="$router.push({name: 'importit'})">
            <v-list-item-icon><v-icon>mdi-import</v-icon></v-list-item-icon>
            <v-list-item-content>Import</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isLoggedIn" @click.stop="logout">
            <v-list-item-icon><v-icon>mdi-login</v-icon></v-list-item-icon>
            <v-list-item-content>Logout</v-list-item-content>
          </v-list-item>
          <v-list-item v-else @click.stop="login">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-content>Login</v-list-item-content>
          </v-list-item>
          <v-list-item href="https://github.com/brianmhess/swizzle-issues/issues" target="_blank">
            <v-list-item-icon><v-icon>mdi-message-alert-outline</v-icon></v-list-item-icon>
            <v-list-item-content>Report Issue</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-dialog v-model="help">
        <v-card>
          <v-card-title>Welcome to Swizzle!</v-card-title>
          <v-card-text class="text-left">
            This website allows you to search cocktail recipes.
            You can also create new recipes to share with everyone,
            or just some folks. You can also create a Bar that is stocked
            with ingredients and show which ingredients in a recipe you have, 
            and other things (see below).
            <br/><br/>
            Cheers!
          </v-card-text>
          <v-card-title class="text-left blue--text">Recipe Search</v-card-title>
          <v-card-text class="text-left">
            From this page you can search recipes in Swizzle. 
            <br/><br/>
            The search bar up top is a text search that will search drink names.
            There are a number of filters you can also apply. They are shown/hidden 
            with the <v-icon>mdi-filter-menu-outline</v-icon> button. 
            <br/><br/>
            The "Include" box allows you to specify ingredients that must appear
            in the drink results. The "Exclude" box allows you to specify ingredients
            that cannot appear in the drink results.
            <br/><br/>
            If you are logged in, you can restrict to only drink you have created 
            using the toggle. If you have chosen a Bar, you can restrict to only drinks
            that include ingredients that are available in the chosen Bar using the toggle.
            <br/><br/>
            The "Clear" button clears all filters.
          </v-card-text>
          <v-card-title class="text-left blue--text">Login</v-card-title>
          <v-card-text class="text-left">
            You can sign in using Google by clicking the bartender menu and choosing "Login".
            <br/><br/>
            Once you are signed in, you can create recipes and bars. You can also edit
            and delete recipes and bars that you have created.
            <br/><br/>
            To log out, click the bartender menu and choose "Logout".
          </v-card-text>
          <v-card-title class="text-left blue--text">Bars</v-card-title>
          <v-card-text class="text-left">
            Bars are essentially a collection of ingredients. This is a way to restrict 
            recipe searches to just ingredients in the currently chosen bar. Additionally,
            when viewing a drink, there is an indicator for each ingredient as to if it
            is currently in the chosen bar <v-icon>mdi-circle-medium</v-icon> or 
            not <v-icon>mdi-square-medium-outline</v-icon>.
            <br/><br/>
            To choose a current bar, click the bartender menu and choose a bar from the 
            pull-down menu. You can clear the pull-down menu to clear the current bar.
            If a bar is chosen, there will be an option in the bartender menu to go to 
            the Bar View for the bar. There is also an option to view the list of all 
            bars that you have permissions for (public bars, shared bars, and your own
            private bars). From the Bar List View, you can create a new bar.
            <br/><br/>
            From the Bar View you can view the ingredients in the bar. This includes 
            a list of bottles for each ingredient. Click on an ingredient to expand to
            see the list of bottles (if any). For example, you may have Bourbon in
            your bar, but you can specify that you have Buffalo Trace and High West 
            (for example). It is not necessary to list specific bottles for any ingredient.
            If you are viewing a drink recipe, and the current bar has some bottles for 
            one (or more) of the ingredients, then there is a pull-down menu to choose
            a particular bottle (or just use the general ingredient).
            <br/><br/>
            You can, of course, add or delete ingredients to/from the bar. To delete an
            ingredient, click the <v-icon>mdi-delete</v-icon> icon next to the bottle or
            ingredient. To add an ingredient or bottle, click the "Add Bottle" button. You
            will be presented with a small form where you can choose the ingredient, and 
            optionally add a name for the bottle. For example, if you already have Buffalo
            Trace bourbon and High West bourbon, and you want to add Basil Hayden's bourbon,
            you would click the "Add Bottle" button, choose "Bourbon" and input "Basil Hayden's",
            which will add that bottle to the list of Bourbons.
            <br/><br/>
            Bars can be Public (seen by everyone) or Private. Private bars can be shared 
            with individual users by specifying their username (Google email address).
          </v-card-text>
          <v-card-title class="text-left blue--text">Recipes</v-card-title>
          <v-card-text class="text-left">
            You can create a new recipy by clicking the bartender menu and choosing
            "New Recipe". From here, specify the details (name, description, origin), 
            ingredients, glass, etc. Recipes can be Public (seen by everyone), Private
            (visible only to you, and whomever you share with), or Semi-Private (if it
            is not your recipe, or hasn't been shared with you, you will only see the 
            ingredients but not the quantities).
            <br/><br/>
            You can also create a variation of an existing drink. While viewing the 
            drink recipe, click "New Variation". This will take you to the new recipe
            page, but will link the recipe to the source drink. When you view a drink
            that has variations, you will see the other drink variations listed, too.
            <br/><br/>
            You can also import a drink recipe from a URL. This is a semi-automated import.
            Swizzle will try to parse the webpage at the URL to extract the recipe, but it 
            does not work with all websites. If it does work, the name, ingredients, and 
            instructions will be shown as hints to those fields. Fill in the fields based on
            those hints. One reason for this is that some ingredients on the website might not 
            match exactly with the list of ingredients in Swizzle. You will want to paraphrase
            the instructions. Make sure to fill in the rest of the fields, such as the Glass.
          </v-card-text>
          <v-card-title class="text-left blue--text">Issues</v-card-title>
          <v-card-text class="text-left">
            Please help us make Swizzle even better. There is a place for submitting an issue
            or suggestion by clicking the bartender menu and choosing "Report Issue". This
            will take you to GitHub Issues, where you can share your problem or idea/suggestion.
          </v-card-text>

          <v-card-actions>
            <v-btn @click.stop="help = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <snackbar/>
  </v-app>
</template>

<script>
import store from '@/store/index.js'
import router from '@/router';
import Snackbar from '@/components/Snackbar'
import { bus } from '@/main'
import backend from '@/plugins/backend'

export default {
  name: 'App',
  components: {
    'snackbar': Snackbar,
    // 'bkheader': RecipeHeader,
  },
  data() {
    return {
      current_bar: this.$store.getters.bar(),
      available_bars: [],
      drawer: false,
      help: false,
    }
  },
  mounted() {
    backend.post("/bars")
          .then(r => {
            this.available_bars = r.data
          })
    store.dispatch('load_ingredients')
  },
  computed: {
    isLoggedIn() {
      return store.getters.user() != null;
    },
  },
  methods: {
    logout() {
      store.dispatch('unset_user').then(() => {
        bus.$emit('snackbar', 'You are now logged out')
        router.push({name: 'home'})
      })
    },
    login() {
      router.push({name: 'login'})
    },
    change_bar() {
      if (this.current_bar) {
        store.dispatch('set_bar_by_id', this.current_bar.idBar).then(() => {
          bus.$emit('snackbar', `Bar ${this.current_bar.strBar} selected`)
        })
      }
      else {
        store.dispatch('unset_bar').then(() => {
          bus.$emit('snackbar', 'No current bar selected')
        })
      }
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
