<template>
    <div>
        <v-container>
            <v-row justify="center">
                <h2>Sign in with Google</h2>
            </v-row>
            <v-row justify="center">
                <div id="signin_button"></div>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import store from '@/store/index.js'
import router from '@/router';

export default {
    name: 'LoginView',
    data() {
        return {
        }
    },
    mounted() {
            window.google.accounts.id.initialize({
                client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
                callback: this.handleCredentialResponse
            });
            window.google.accounts.id.renderButton(
                document.getElementById("signin_button"),
                { theme: "outline", size: "large" }  // customization attributes
            );
        },
    methods: {
        handleCredentialResponse(response) {
            const token = jwt_decode(response.credential)
            store.dispatch('set_user', {type: 'google', id: token.email, details: token}).then(
                () => router.push('search')
            )
        }
    },
}

</script>