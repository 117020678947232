import Vue from 'vue'
import Vuex from 'vuex'
import { setStore, getStore } from '@/utils'
import backend from '@/plugins/backend'

Vue.use(Vuex)

const user = getStore('user')
if (user)
  backend.defaults.headers.common['swizzle-user'] = user.id
const bar = getStore('bar')

export default new Vuex.Store({
  state: {
    search: null,
    user: user,
    bar: bar,
    superusers: ['brianmhess@gmail.com'],
    all_ingredients: null,
    ingredient_groups: null,
    ingredient_types: null,
    ingredient_state: 'INIT',
  },
  getters: {
    search: (state) => () => {
      return state.search
    },
    user: (state) => () => {
      return state.user
    },
    bar_id: (state) => () => {
      return state.bar.id
    },
    bar: (state) => () => {
      return state.bar
    },
    superusers: (state) => () => {
      return state.superusers
    },
    ingredient_state: (state) => () => {
      return state.ingredient_state;
    },
    all_ingredients: (state) => () => {
      return state.all_ingredients
    },
    ingredient_groups: (state) => () => {
      return state.ingredient_groups
    },
    ingredient_types: (state) => () => {
      return state.ingredient_types
    }
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search
    },
    SET_USER(state, user) {
      state.user = user
      if (user)
        backend.defaults.headers.common["swizzle-user"] = user.id
      else
        delete backend.defaults.headers.common["swizzle-user"]
      setStore('user', user)
    },
    SET_BAR(state, bar) {
      state.bar = bar
      setStore('bar', bar)
    },
    SET_INGREDIENT_STATE(state, val) {
      state.ingredient_state = val
    },
    SET_INGREDIENTS(state, tingobj) {
      var tinglist = []
      var tingmap = {}
      var ting_types = ['All']
      Object.entries(tingobj).forEach(function(e) {tingmap[e[0]] = e[1]; tinglist = [...tinglist, {'header': e[0]}, ...e[1]]; ting_types = [...ting_types, e[0]];})
      tingmap['All'] = tinglist 
      state.all_ingredients = tinglist;
      state.ingredient_groups = tingmap;
      state.ingredient_types = ting_types;
  }
  },
  actions: {
    save_search({commit}, search) {
      commit('SET_SEARCH', search)
    },
    set_user({commit}, user) {
      commit('SET_USER', user)
    },
    unset_user({commit}) {
      commit('SET_USER', null)
      commit('SET_BAR', null)
    },
    set_bar({commit}, bar) {
      commit('SET_BAR', bar)
    },
    set_bar_by_id({commit}, bar_id) {
      backend.post("/bar/" + bar_id)
      .then(r => {
        commit('SET_BAR', r.data)
      })
    },
    unset_bar({commit}) {
      commit('SET_BAR', null)
    },
    load_ingredients({commit}) {
      commit('SET_INGREDIENT_STATE', 'LOADING')
      backend.post("/ingredients")
        .then(r => {
          var tingobj = r.data.reduce(function(rv, x) { (rv[x['strType']] = rv[x['strType']] || []).push(x); return rv;}, {})
          commit('SET_INGREDIENTS', tingobj)
          commit('SET_INGREDIENT_STATE', 'LOADED')
        })
    },
  },
})
