<template>
    <div class="recipe-notes">
        <v-card class="mx-auto" v-if="isLoggedIn || whichnotes.length > 0">
            <v-card-title class="white--text orange darken-4" height="50">
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer v-if="isLoggedIn"></v-spacer>
                Notes
                <v-spacer></v-spacer>
                <v-checkbox v-if="isLoggedIn" v-model="just_my_notes" dense hide-details dark color="white">
                    <template v-slot:label>
                            <span class="text-caption">Just My Notes</span>
                        </template>
                </v-checkbox>
                <v-spacer></v-spacer>
                <v-btn v-if="isLoggedIn"
                    color="white"
                    class="text--primary"
                    fab
                    x-small
                    @click="new_note_modal=true"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>


            </v-card-title>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item v-for="item,idx in whichnotes" :key="idx" :style="idx%2 ? 'background-color: #EEEEEE' : 'background-color: white'">
                    <v-list-item-avatar>
                        <v-avatar
                            color="blue"
                            size="30"
                            class="white--text"
                        >
                            {{ display_name(item.idUser)[0] }}
                        </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content :color="'blue'">
                        <v-list-item-title class="text-left">
                            <v-row justify="space-between">
                                <v-col>{{ display_name(item.idUser) }}</v-col>
                                <v-col v-if="item.strRating.length == 0"></v-col>
                                <v-col v-else>
                                    <v-tooltip bottom color="red" v-if="item.strRating == '0'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mx-1" color="red" v-bind="attrs" v-on="on" x-small elevation="0">
                                                <v-icon x-small>mdi-thumb-down</v-icon><v-icon x-small>mdi-thumb-down</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Nope</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="orange" v-if="item.strRating == '1'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mx-1" color="orange" v-bind="attrs" v-on="on" x-small elevation="0">
                                                <v-icon x-small>mdi-thumb-down</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Not Really</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="amber" v-if="item.strRating == '2'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mx-1" color="amber" v-bind="attrs" v-on="on" x-small elevation="0">
                                                <v-icon x-small>mdi-hand-extended</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ehh</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="green" v-if="item.strRating == '3'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mx-1" color="green" v-bind="attrs" v-on="on" x-small elevation="0">
                                                <v-icon x-small>mdi-thumb-up</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Liked It</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="blue" v-if="item.strRating == '4'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mx-1" color="blue" v-bind="attrs" v-on="on" x-small elevation="0">
                                                <v-icon x-small>mdi-thumb-up</v-icon><v-icon x-small>mdi-thumb-up</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Hell's Yeah</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col>{{ item.strDatetime }}</v-col>
                            </v-row>
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-left" v-if="item.strNote.length > 0">{{ item.strNote }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="can_edit(item)">
                        <v-btn-toggle>
                            <v-btn small @click="edit_note(item)" color="primary lighten-3"><v-icon>mdi-pencil</v-icon></v-btn>
                            <v-btn small @click="delete_note(item)" color="secondary lighten-3"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-btn-toggle>
                    </v-list-item-action>
                </v-list-item>
            </v-list>

            <v-dialog v-model="new_note_modal">
                <v-card>
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td>Note:</td>
                                <td>
                                    <v-textarea v-model="new_note.strNote"></v-textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>Rating:</td>
                                <td>
                                    <v-tooltip bottom color="red">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-hover v-slot="{ hover }">
                                                <v-btn @click="new_note.strRating='0'" class="mx-1" :color="(hover || new_note.strRating == '0') ? 'red' : 'light-grey'" v-bind="attrs" v-on="on" x-small elevation="0">
                                                    <v-icon x-small>mdi-thumb-down</v-icon><v-icon x-small>mdi-thumb-down</v-icon>
                                                </v-btn>
                                            </v-hover>
                                        </template>
                                        <span>Nope</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="orange">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-hover v-slot="{ hover }">
                                                <v-btn @click="new_note.strRating='1'" class="mx-1" :color="(hover || new_note.strRating == '1') ? 'orange' : 'light-grey'" v-bind="attrs" v-on="on" x-small elevation="0">
                                                    <v-icon x-small>mdi-thumb-down</v-icon>
                                                </v-btn>
                                            </v-hover>
                                        </template>
                                        <span>Not Really</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="amber">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-hover v-slot="{ hover }">
                                                <v-btn @click="new_note.strRating='2'" class="mx-1" :color="(hover || new_note.strRating == '2') ? 'amber' : 'light-grey'" v-bind="attrs" v-on="on" x-small elevation="0">
                                                    <v-icon x-small>mdi-hand-extended</v-icon>
                                                </v-btn>
                                            </v-hover>
                                        </template>
                                        <span>Ehh</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="green">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-hover v-slot="{ hover }">
                                                <v-btn @click="new_note.strRating='3'" class="mx-1" :color="(hover || new_note.strRating == '3') ? 'green' : 'light-grey'" v-bind="attrs" v-on="on" x-small elevation="0">
                                                    <v-icon x-small>mdi-thumb-up</v-icon>
                                                </v-btn>
                                            </v-hover>
                                        </template>
                                        <span>Liked It</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="blue">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-hover v-slot="{ hover }">
                                                <v-btn @click="new_note.strRating='4'" class="mx-1" :color="(hover || new_note.strRating == '4') ? 'blue' : 'light-grey'" v-bind="attrs" v-on="on" x-small elevation="0">
                                                    <v-icon x-small>mdi-thumb-up</v-icon><v-icon x-small>mdi-thumb-up</v-icon>
                                                </v-btn>
                                            </v-hover>
                                        </template>
                                        <span>Hell's Yeah</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                            <tr><td>Visibility</td>
                                <td style="vertical-align:middle; text-align:left">
                                    <v-select
                                        v-model="new_note.visibility"
                                        hint="Visibility"
                                        :items="visibilities"
                                    >
                                    </v-select>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-card-actions>
                        <v-row>
                            <v-col><v-btn @click="save_note" color="primary">Save</v-btn></v-col>
                            <v-spacer></v-spacer>
                            <v-col><v-btn @click="new_note_modal=false" color="secondary">Cancel</v-btn></v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import backend from '@/plugins/backend'
import { bus } from '@/main'

export default {
    name: "recipe-notes",
    props: {
        recipe_id: String,
    },
    data() {
        return {
            notes: [],
            new_note: {
                strNote: "",
                strRating: "",
                visibility: "Public",
                // intRating: null,
            },
            new_note_id: null,
            new_note_modal: false,
            // user_id: "",
            just_my_notes: false,
            visibilities: ['Public', 'Private'],
            rating_colors: ['red', 'orange', 'amber', 'green', 'blue'],
            rating_hints: ['Nope', 'Not Really', 'Ehh', 'Liked It', "Hell's Yeah"],
            rating_icons: ['mdi-thumb-down', 'mdi-thumb-down', 'mdi-hand-extended', 'mdi-thumb-up', 'mdi-thumb-up']
        }
    },
    mounted() {
        // this.user_id = this.$store.getters.user().id
        // backend.get("/recipe/" + this.recipe_id + "/notes/" + this.user_id)
        //     .then(r => {
        //         this.notes = r.data
        //     })
        backend.get("/recipe/" + this.recipe_id + "/notes")
            .then(r => {
                this.notes = r.data
            })
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.user() != null;
        },
        whichnotes() {
            if (this.just_my_notes)
                return this.notes.filter(e => e.idUser == this.user_id)
            return this.notes
        },
        user_id() {
            let tuser = this.$store.getters.user()
            return tuser ? tuser.id : null
        },
    },
    methods: {
        display_name(c) {
            return c.split('@')[0]
        },
        parse_and_scale(str) {
            return parseInt(str) + 1
        },
        scale_and_convert(i) {
            return (i-1).toString()
        },
        can_edit(note) {
            return this.isLoggedIn && (this.$store.getters.user().id == note.idUser 
                    || this.$store.getters.superusers().includes(this.$store.getters.user().id))
        },
        edit_note(note) {
            this.new_note_id = note.idNote
            this.new_note.strNote = note.strNote
            this.new_note.strRating = note.strRating
            // this.new_note.intRating = parseInt(note.strRating)
            this.new_note.visibility = note.visibility
            this.new_note_modal = true
        },
        delete_note(note) {
            var url = "/recipe/" + this.recipe_id + "/notes/" + note.idUser + "/note/" + note.idNote
            backend.delete(url)
                .then(r => {
                    backend.get("/recipe/" + this.recipe_id + "/notes")
                        .then(r => {
                            this.notes = r.data
                            this.new_note_modal = false
                            this.new_note = {strNote: "", strRating: "", visibility: "Public"}
                            bus.$emit('snackbar', 'Note deleted')
                        })
                })
        },
        save_note() {
            // this.new_note.strRating = this.new_note.intRating ? this.new_note.intRating.toString() : ""
            var url = "/recipe/" + this.recipe_id + "/notes/" + this.user_id
            if (this.new_note_id) {
                url = "/recipe/" + this.recipe_id + "/notes/" + this.user_id + "/note/" + this.new_note_id
            }
            backend.put(url, this.new_note)
                .then(r => {
                    backend.get("/recipe/" + this.recipe_id + "/notes")
                        .then(r => {
                            this.notes = r.data
                            this.new_note_modal = false
                            this.new_note = {strNote: "", strRating: "", visibility: "Public"}
                            bus.$emit('snackbar', 'Note saved')
                        })
                })
        }
    },
}
</script>
