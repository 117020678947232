<template>
    <div class="edit-recipe">
        <v-card class="ma-3 pa-3" v-if="all_ingredients && recipe">
            <v-simple-table>
                <tbody>
                    <tr><td style="vertical-align:top; text-align:left"><h3>Drink Name</h3></td>
                        <td style="vertical-align:middle">
                            <v-text-field class="my-2" v-model="recipe.strDrink" label="Drink Name" solo :rules="[recipe_name_rules.available]"
                                :hide-details="t_titleHint==null" :hint="t_titleHint" persistent-hint
                            >
                            </v-text-field>
                        </td>
                    </tr>
                    <tr><td style="vertical-align:top; text-align:left"><h3>Description</h3></td>
                        <td style="vertical-align:middle"><v-text-field hide-details class="my-2" v-model="recipe.strDescription" label="Description" solo></v-text-field></td>
                    </tr>
                    <tr><td style="vertical-align:top; text-align:left"><h3>Origin</h3></td>
                        <td style="vertical-align:middle">
                            <v-textarea class="my-2" v-model="recipe.strOrigin" label="Where did this drink come from" rows="3"
                                :hide-details="t_originHint==null" :hint="t_originHint" persistent-hint
                            >
                            </v-textarea>
                        </td>
                    </tr>
                    <tr><td style="vertical-align:top; text-align:left" colspan="2"><h3>Ingredients</h3></td></tr>
                    <tr>
                        <td style="vertical-align:middle" colspan="2">
                            <v-simple-table dense>
                                <tbody>
                                    <tr v-for="ing,idx in t_ingredients" :key="idx">
                                        <td style="vertical-align:middle">
                                            <v-autocomplete hide-details class="my-2"
                                                v-model="ing.ingredient"
                                                :label="ing.ingredientHint ? ing.ingredientHint : 'Ingredient ' + (idx+1)"
                                                :hint="ing.ingredientHint ? ing.ingredientHint : ''"
                                                persistent-hint
                                                :items="ingredients"
                                                item-text="strIngredient"
                                                item-value="idIngredient"
                                                return-object
                                                :filter="returnHeadersAndFilters"
                                            >
                                                <template v-slot:prepend-item>
                                                    <span>
                                                        <v-select dense outlined v-model="t_ingredient_type" :items="ingredient_types" label="Ingredient Type">
                                                            <template v-slot:item="{ item }">
                                                                <span class="d-flex justify-left" style="width: 100%;">
                                                                    {{ item }}
                                                                </span>
                                                            </template>
                                                        </v-select>
                                                    </span>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <span class="d-flex justify-left" style="width: 100%;">
                                                        {{ item.strIngredient }}
                                                    </span>
                                                </template>
                                            </v-autocomplete>
                                        </td>
                                        <td style="vertical-align:middle">
                                            <v-text-field hide-details class="my-2"
                                                v-model="ing.strMeasure"
                                                :label="'Measure ' + (idx+1)"
                                                solo
                                            ></v-text-field>
                                        </td>
                                        <td style="vertical-align:middle">
                                            <v-btn @click.stop="deleteIngredient(idx)" class="my-2">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align:middle"></td>
                                        <td style="vertical-align:middle"></td>
                                        <td style="vertical-align:middle"><v-btn @click.stop="addIngredient()" class="my-2"><v-icon>mdi-plus</v-icon></v-btn></td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </td>                        
                    </tr>


                    <tr><td style="vertical-align:top; text-align:left"><h3>Instructions</h3></td>
                        <td style="vertical-align:middle">
                            <v-textarea :hide-details="t_instructionsHint==null" class="my-2"
                                v-model="recipe.strInstructions"
                                label="Instructions"
                                outlined rows="3"
                                :hint="t_instructionsHint"
                                persistent-hint
                            ></v-textarea>
                        </td>
                    </tr>

                    <tr><td style="vertical-align:top; text-align:left"><h3>Glass</h3></td>
                        <td style="vertical-align:middle">
                            <v-autocomplete hide-details class="my-2"
                                v-model="recipe.glass"
                                label="Glass"
                                :items="glasses"
                                item-text="strGlass"
                                item-value="idGlass"
                                return-object
                            ></v-autocomplete>
                        </td>
                    </tr>
                    <tr><td style="vertical-align:top; text-align:left"><h3>Variation of</h3></td>
                        <td style="vertical-align:middle; text-align:left"><h3><a :href="variation_url">{{ variation_str }}</a></h3></td>
                    </tr>
                    <tr><td style="vertical-align:top; text-align:left"><h3>Visibility</h3></td>
                        <td style="vertical-align:middle; text-align:left">
                            <v-select
                                v-model="recipe.visibility"
                                hint="Visibility"
                                :items="visibilities"
                            >
                            </v-select>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>

            <v-card-actions>
                <v-row>
                    <v-col>
                        <v-btn
                            color="primary"
                            @click="saveRecipe"
                        >Save Recipe</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn
                            color="secondary"
                            @click="$router.go(-1)"
                        >Cancel</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import backend from '@/plugins/backend'
import { bus } from '@/main'

export default {
    name: "edit-recipe",
    data() {
        return {
            recipe_id: this.$route.params.recipe_id,
            variation_id: this.$route.params.variation_id,
            import_url: this.$route.params.import_url,
            variation_str: null,
            recipe: null,
            t_glass: null,
            t_ingredients: [],
            t_instructionsHint: null,
            t_originHint: null,
            t_titleHint: null,
            glasses: [],
            t_ingredient_type: 'All',
            visibilities: ['Public', 'SemiPrivate', 'Private'],
            original_name: null,
            recipe_names: [],
            recipe_name_rules: {
                available: value => {
                    if (this.original_name == value)
                        return true
                    return !this.recipe_names.includes(value) || 'Recipe with that name already exists'
                }
            },
        }
    },
    mounted() {
        backend.post("/glasses")
            .then(r => {
                this.glasses = r.data
            })
        backend.post("/recipe_names")
            .then(r => {
                this.recipe_names = r.data
             })
        if (this.recipe_id != null) {
            backend.get("/recipe/" + this.recipe_id)
                .then(r => {
                    this.recipe = r.data
                    this.original_name = this.recipe.strDrink
                    this.t_ingredients = this.recipe.ingredients
                })
                .then(r => {
                    if (this.recipe.idVariation)
                        backend.get("/recipe/" + this.recipe.idVariation)
                            .then(r => {
                                this.variation_str = r.data.strDrink
                            })
                })
        }
        else if (this.variation_id != null) {
            backend.get("/recipe/" + this.variation_id)
                .then(r => {
                    this.recipe = r.data
                    this.t_ingredients = this.recipe.ingredients

                    this.recipe.idDrink = null
                    this.recipe.strDrink = `Variation of ${r.data.strDrink}`
                    this.recipe.idVariation = this.variation_id
                    this.recipe.visibility = 'Public'
                })
                .then(r => {
                    if (this.recipe.idVariation)
                        backend.get("/recipe/" + this.recipe.idVariation)
                            .then(r => {
                                this.variation_str = r.data.strDrink
                            })
                })
        }
        else if (this.import_url != null) {
            console.log("Importing it:", this.import_url)
            backend.post("/recipe_scrape", {url: this.import_url})
                .then(r => {
                    console.log("scraper", r)
                    this.t_ingredients = r.data.ingredients.map(e => ({ingredient: {idIngredient: null, strIngredient: null}, ingredientHint: e, strMeasure: ""}))
                    this.t_instructionsHint = r.data.instructions
                    this.t_originHint = r.data.host
                    this.t_titleHint = r.data.title

                    this.recipe = {
                        idDrink: null,
                        strDrink: "",
                        strDescription: "",
                        strInstructions: "",
                        ingredients: [ ],
                        glass: null,
                        strOrigin: "",
                        strCreatedBy: "",
                        idVariation: null,
                        visibility: 'Public',
                    }
                })
                .catch(e => {
                    bus.$emit('snackbar', 'Unable to import recipe. Please try manually. Sorry :(')

                    this.recipe = {
                        idDrink: null,
                        strDrink: "",
                        strDescription: "",
                        strInstructions: "",
                        ingredients: [ ],
                        glass: null,
                        strOrigin: "",
                        strCreatedBy: "",
                        idVariation: null,
                        visibility: 'Public',
                    }
                })
        }
        else {
            this.recipe = {
                idDrink: null,
                strDrink: "",
                strDescription: "",
                strInstructions: "",
                ingredients: [ ],
                glass: null,
                strOrigin: "",
                strCreatedBy: "",
                idVariation: null,
                visibility: 'Public',
            }
        }   
    },
    computed: {
        variation_url() {
            return "/recipe/" + this.variation_id
        },
        all_ingredients() {
            return this.$store.getters.all_ingredients()
        },
        ingredient_types() {
            return this.$store.getters.ingredient_types()
        },
        ingredient_groups() {
            return this.$store.getters.ingredient_groups()
        },
        ingredients() {
            if (this.t_ingredient_type == 'All')
                return this.all_ingredients
            return this.all_ingredients.filter(e => e.strType && e.strType == this.t_ingredient_type)
        },
    },
    methods: {
        returnHeadersAndFilters(item, queryText, itemText) {
            if (item.header) {
                return [...this.ingredient_groups[item.header]].filter(e => e.strIngredient.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1).length > 0
            }
            return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        },
        deleteIngredient(idx) {
            this.t_ingredients.splice(idx, 1)
        },
        addIngredient() {
            this.t_ingredients.push({ingredient: {idIngredient: null, strIngredient: null}, strMeasure: ""})
        },
        saveRecipe() {
            this.recipe.strDrink = this.recipe.strDrink.trim()
            var idx = 1
            this.recipe.ingredients = this.t_ingredients.map(e => ({order: idx++, ingredient: e.ingredient, strMeasure: e.strMeasure}))
            this.recipe.strCreatedBy = this.$store.getters.user().id
            // Save recipe and Redirect to recipe view of the new recipe
            console.log("Recipe: ", this.recipe)
            var url = "/recipe"
            if (this.recipe.idDrink)
                url = "/recipe/" + this.recipe.idDrink
            backend.put(url, this.recipe)
                     .then(r => {
                        bus.$emit('snackbar', 'Recipe saved')
                        this.$router.push({name: 'recipe_view', params: {'recipe_id': r.data.idDrink}})
                     })
        },
    }
}

</script>