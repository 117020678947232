<template>
    <div class="page_not_found">
        <p>404 Page not Found</p>
        <router-link to="/login">Go Back to Login</router-link>
    </div>
</template>

<script>
export default {
  name: 'page_not_found'
}
</script>