import Vue from 'vue'
import VueRouter from 'vue-router'
// import RecipeListView from '@/views/RecipeListView.vue'
import RecipeSearchView from '@/views/RecipeSearchView.vue'
import RecipeDetailsView from '@/views/RecipeDetailsView.vue'
import AiRecipeSearch from '@/views/AiRecipeSearch.vue'
import EditRecipeView from '@/views/EditRecipeView.vue'
import BarView from '@/views/BarView.vue'
import BarListView from '@/views/BarListView.vue'
import ImportItView from '@/views/ImportItView.vue'

import LoginView from '@/views/LoginView.vue'
import PageNotFound from '@/views/PageNotFoundView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: RecipeSearchView,
  },
  {
    path: '/ai',
    name: 'ai',
    component: AiRecipeSearch,
  },
  // {
  //   path: '/list',
  //   name: 'list',
  //   component: RecipeListView,
  // },
  {
    path: '/search',
    name: 'search',
    component: RecipeSearchView,
  },
  {
    path: '/recipe/:recipe_id',
    name: 'recipe_view',
    component: RecipeDetailsView,
  },
  {
    path: '/edit_recipe/:recipe_id',
    name: 'edit_recipe',
    component: EditRecipeView,
  },
  {
    path: '/new_recipe',
    name: 'new_recipe',
    component: EditRecipeView,
  },
  {
    path: '/importit',
    name: 'importit',
    component: ImportItView,
  },
  {
    path: '/new_bar',
    name: 'new_bar',
    component: BarView,
  },
  {
    path: '/bar/:bar_id',
    name: 'bar_view',
    component: BarView,
  },
  {
    path: '/bars',
    name: 'bar_list_view',
    component: BarListView,
  },

  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_LOCALUSER)
    next()
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['login', 'home', 'search', 'recipe_view', 'bar_view', 'bar_list_view']
  const authRequired = !publicPages.includes(to.name)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    return next('/login')
  }
  next()
})


export default router
